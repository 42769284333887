import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../styles/base.scss'
import styles from './layout.module.scss'
import Metadata from '../components/metadata'
import PageTransition from '../components/page-transition'

const Layout = ({ site, page, children, location, transitionDelay }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTransitioned(true)
    }, transitionDelay)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <Metadata
        title={page.title}
        description={site.metaDescription}
        subtitle={site.metaSubtitle}
        shareImage={page.shareImage || site.shareImage}
      />
      <div
        className={styles.el}
        data-transition={isTransitioned ? 'visible' : 'hidden'}
      >
        <header className={styles.el__header}>
          <h1 className={styles.el__logo}>
            <div className={styles.el__logo__inner}>{site.title}</div>
          </h1>
        </header>
        <main className={styles.el__main}>
          <PageTransition location={location}>{children}</PageTransition>
        </main>
      </div>
    </>
  )
}

Layout.defaultProps = {
  site: {
    title: '',
    metaDescription: '',
    metaSubtitle: '',
    shareImage: '',
  },
  page: {
    title: '',
    shareImage: '',
    path: '',
  },
  location: {},
  transitionDelay: 700,
}

Layout.propTypes = {
  site: PropTypes.shape({
    title: PropTypes.string,
    metaDescription: PropTypes.string,
    metaSubtitle: PropTypes.string,
    shareImage: PropTypes.string,
  }),
  page: PropTypes.shape({
    title: PropTypes.string,
    shareImage: PropTypes.string,
    path: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  transitionDelay: PropTypes.number,
}

export default Layout
