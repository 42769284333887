module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Paramount House","short_name":"ParamountHouse","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/icon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/prismic/layout/layout.prismic.jsx"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"paramounthouse","accessToken":"MC5Ya1pnRHhFQUFDSUFPTmxJ.77-9du-_vTcW77-977-9S2Lvv70lL2Mw77-977-9ZO-_ve-_vWPvv71B77-977-977-9E08F77-9Pe-_vUU","schemas":{"holding_page":{"Main":{"title":{"type":"Text","config":{"label":"Title","placeholder":"Title"}},"uid":{"type":"UID","config":{"label":"UID","placeholder":"page-slug"}},"heading":{"type":"StructuredText","config":{"multi":"strong, em, hyperlink","allowTargetBlank":true,"label":"Heading","placeholder":"Heading"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, strong, em, hyperlink","allowTargetBlank":true,"label":"Content","placeholder":"Content"}},"business_list":{"type":"Group","config":{"fields":{"name":{"type":"Text","config":{"label":"Name","placeholder":"Business Name"}},"url":{"type":"Text","config":{"label":"URL","placeholder":"URL"}}},"label":"Business List"}},"footer_columns":{"type":"Group","config":{"fields":{"content":{"type":"StructuredText","config":{"multi":"paragraph, strong, em, hyperlink, list-item, o-list-item","allowTargetBlank":true,"label":"Content","placeholder":"Content"}}},"label":"Footer Columns"}}}},"site_config":{"General Settings":{"title":{"type":"Text","config":{"label":"Site Title","placeholder":"Website Title"}},"meta_subtitle":{"type":"Text","config":{"label":"Meta Subtitle","placeholder":"Site meta subtitle"}},"meta_description":{"type":"Text","config":{"label":"Meta Description","placeholder":"Site meta description"}},"meta_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Share Thumb (Recommended: 1200x630px)"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
