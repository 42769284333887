import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

const fadeDuration = 0.2

const containerAni = {
  initial: {},
  enter: {},
  exit: {},
}

const contentAni = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      fadeDuration,
      delay: fadeDuration + 0.25,
    },
  },
  exit: {
    opacity: 0,
    transition: { fadeDuration },
  },
}

const PageTransition = ({ children, location }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={location.pathname}
        variants={containerAni}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        <motion.div key={`${location.pathname}_content`} variants={contentAni}>
          {children}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  )
}

PageTransition.defaultProps = {
  children: undefined,
  location: {},
}

PageTransition.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export default PageTransition
