import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../../layout'
import { getSiteConfig } from '../utils/get-site-config'

const LayoutPrismic = ({ path, pageContext, children, location }) => {
  const site = getSiteConfig() || {}

  const pageData = {
    title: pageContext.title,
    path,
    transparentHeader: pageContext.transparentHeader,
    hideLogo: pageContext.hideLogo,
    newsletterSubscribe: pageContext.newsletterSubscribe,
    shareImage: pageContext.shareImage,
  }

  return (
    <Layout site={site} page={pageData} location={location}>
      {children}
    </Layout>
  )
}

LayoutPrismic.defaultProps = {
  children: undefined,
  path: '',
  pageContext: {},
  location: {},
}

LayoutPrismic.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default LayoutPrismic
