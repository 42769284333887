import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Metadata({ description, subtitle, lang, meta, title, shareImage }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            shareImage
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaShareImage =
    shareImage || `${site.siteMetadata.siteUrl}${site.siteMetadata.shareImage}`

  let defaultTitle
  if (subtitle || site.siteMetadata.subtitle) {
    defaultTitle = `${site.siteMetadata.title}: ${subtitle ||
      site.siteMetadata.subtitle}`
  } else {
    defaultTitle = `${site.siteMetadata.title}`
  }

  const metaTitle = title
    ? `${title} – ${site.siteMetadata.title}`
    : defaultTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s – ${site.siteMetadata.title}`}
      defaultTitle={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1.0, maximum-scale=5.0`,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaShareImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image:src`,
          content: metaShareImage,
        },
      ].concat(meta)}
    />
  )
}

Metadata.defaultProps = {
  title: '',
  lang: 'en',
  meta: [],
  description: '',
  subtitle: '',
  shareImage: '',
}

Metadata.propTypes = {
  description: PropTypes.string,
  subtitle: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  shareImage: PropTypes.string,
}

export default Metadata
