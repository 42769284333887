import { useStaticQuery, graphql } from 'gatsby'
import { get } from 'lodash'

export const getSiteConfig = () => {
  const { prismicSiteConfig } = useStaticQuery(
    graphql`
      query SiteConfig {
        prismicSiteConfig {
          data {
            title
            meta_description
            meta_subtitle
            meta_image {
              url
            }
          }
        }
      }
    `
  )

  const { data } = prismicSiteConfig

  const siteConfig = {
    title: get(data, 'title'),
    metaDescription: get(data, 'meta_description'),
    metaSubtitle: get(data, 'meta_subtitle'),
    shareImage: get(data, 'meta_image.url'),
  }

  return siteConfig
}
